<template>
  <div v-if="loading">
    <b-skeleton
      animation="fade"
      width="100px"
      height="15px"
      class="pb-0 mb-0"
    />
  </div>
  <div v-else-if="!loading && show.includes('country') && !error">
    {{ country }}
  </div>
  <div v-else>
    <b-btn @click="handleIp(ip)"> Reintentar </b-btn>
  </div>
</template>

<script>
import axios from "axios";

export default {
  name: "IpParse",
  props: ["ip", "show"],
  data() {
    return {
      loading: true,
      country: "",
      abortController: null,
      error: "",
    };
  },
  watch: {
    ip: {
      handler: function (newIp) {
        this.abortRequest();
        this.handleIp(newIp);
      },
      immediate: true,
    },
  },
  methods: {
    resetData() {
      this.country = "";
    },

    abortRequest() {
      // Abort request if it exists
      if (this.abortController) {
        this.abortController.abort();
      }
    },

    async handleIp(ip) {
      this.error = false;
      if (!ip) return;
      this.loading = true;
      this.resetData();

      // // Abort previous request if it exists
      // if (this.abortController) {
      //   this.abortController.abort();
      // }

      // Create a new AbortController
      this.abortController = new AbortController();

      try {
        const params = new URLSearchParams({
          "api-key": process.env.VUE_APP_IP_DATA_KEY,
        });
        const response = await fetch(`https://api.ipdata.co/${ip}?` + params, {
          signal: this.abortController.signal,
        });
        const data = await response.json();
        this.country = data.country_name + " " + data.emoji_flag;
      } catch (error) {
        if (error.name === "AbortError") {
          // Request was aborted
        } else {
          // Other error occurred
          this.error = true;
        }
      } finally {
        this.loading = false;
        // Reset the abortController
        this.abortController = null;
      }
    },
  },

  beforeDestroy() {
    this.abortRequest();
  },

  mounted() {},
};
</script>

<style scoped></style>