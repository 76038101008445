import { render, staticRenderFns } from "./IpParse.vue?vue&type=template&id=28349c9f&scoped=true"
import script from "./IpParse.vue?vue&type=script&lang=js"
export * from "./IpParse.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "28349c9f",
  null
  
)

export default component.exports